<div class="">
  <div class="main_wrapper">
    <frontend-verify-header-admin (eventCurrentUser)="getCurrentUser($event)"></frontend-verify-header-admin>
    <frontend-side-bar-admin></frontend-side-bar-admin>
    <div class="main_content">
      <div class="back_btn" (click)="redirectBack()">
        <a><img src="assets/images/back_ic.svg">Back</a>
      </div>

      <div class="seller_main">
        <div class="main_tab mt-0 mt-md-3">
          <div class="headings"><h1>Listeners</h1></div>
          <div class="seller_search">
              <input [ngModel]="searchData" (ngModelChange)="search($event)" type=text placeholder="Search listeners"/>
              <span><img src="assets/images/search_tab1.png"></span>
          </div>
        </div>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active">
            <div class="skit_main">
              <div class="skit_tables Desktop_view">
                <div class="table podcast_table">
                  <div class="table_heading_list">
                    <ul>
                      <li class="w-25 cursor-pointer" (click)="sortField('full_name')">
                        <h1>Listener</h1>
                        <span><img src="assets/images/up_down.png"/></span>
                      </li>
                      <li class="w-30 cursor-pointer" (click)="sortField('email')">
                        <h1>Email</h1>
                        <span><img src="assets/images/up_down.png"/></span>
                      </li>
                      <li class="w-10 cursor-pointer" (click)="sortField('createdAt')">
                        <h1>Date Joined</h1>
                        <span><img src="assets/images/up_down.png"/></span>
                      </li>
                      <li class="w-10 cursor-pointer" (click)="sortField('tokens')">
                        <h1>Tokens</h1>
                        <span><img src="assets/images/up_down.png"/></span>
                      </li>
                      <li class="w-10 cursor-pointer">
                        <h1>Token Spent</h1>
                      </li>
                      <li class="w-10 cursor-pointer">
                        <h1>Status</h1>
                      </li>
                      <li class="w-5 cursor-pointer">
                      </li>
                    </ul>
                  </div>
                  <div class="table_main_content">
                    <ng-container *ngFor="let listener of listeners; let i = index">
                      <div class="table_first_list">
                        <ul class="list_ul">
                          <li class="w-25">
                            <div class="skit_details_here">
                              <div class="per">
                                <img [src]="listener.photo || 'assets/images/store.png'"/>
                                <h2>{{ (listener?.full_name || ((listener?.first_name || 'user') + ' ' + (listener?.last_name || ' '))) }}</h2>

                              </div>
                            </div>
                          </li>
                          <li class="w-30">
                            <ul class="store text-capitalize store-mail">
                              {{listener?.email}}
                            </ul>
                          </li>
                          <li class="w-10">
                            <h2>{{listener?.createdAt | date: 'dd MMM, yyyy'}}</h2>
                          </li>
                          <li class="w-10">
                            <h2>{{listener?.tokens || 0}}</h2>
                          </li>
                          <li class="w-10">
                            <h2>{{listener?.spent_tokens || 0}}</h2>
                          </li>
                          <li class="w-10">
                            <span class="status {{(listener?.listener_status ? 'active': 'inactive')}}">
                            <span class="badge"></span>
                              {{listener?.listener_status === true ? ('active' | translate) : ('inactive' | translate)}}
                            </span>
                          </li>
                          <li class="w-5" >
                            <button type="button" class="btn-icon float-end" placement="bottom right"
                                    containerClass="custom-popover no-arrow"
                                    [outsideClick]="true" container="body" [adaptivePosition]="false"
                                    [popover]="popTemplate">
                              <i class="fas fa-ellipsis-v"></i>
                            </button>
                            <ng-template #popTemplate>
                              <div class="item justify-content-between pe-0">
                                <span>{{'status' | translate}}</span>
                                <div class="text-start d-flex align-items-center">
                                  <label class="action-switch-btn me-2 m-0" (click)="listener?.listener_status ? deactivateMember($event, listener) : activateMember($event, listener)">
                                    <input type="checkbox" [checked]="listener?.listener_status">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <div class="item justify-content-between pe-0">
                                <span>Action</span>
                                <div class="text-start d-flex align-items-center">
                                  <label class="action-switch-btn me-2 m-0" (click)="listener?.is_blocked_by_admin ? blockUser($event, listener) : unBlockUser($event, listener)">
                                    <input type="checkbox" [checked]="listener?.is_blocked_by_admin">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </ng-template>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="listeners.length <= 0">
                      <div class="table_first_list text-center">
                        <ul class="list_ul">
                          <li class="d-block w-100">
                            No data found
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="skit_main_mobile">
              <div class="seller_lists">
                <ul *ngIf="listeners.length > 0">
                  <li *ngFor="let listener of listeners">
                    <div class="seller_details">
                      <img [src]="listener.photo || 'assets/images/store.png'">
                      <div class="seller_text">
                        <h1>{{listener?.full_name || (listener?.first_name + ' ' + listener.last_name)}}</h1>
                        <p>{{listener?.email}}</p>

                        <p class="d-flex align-items-center">
                          <span class="me-4">
                            <span class="fw-semibold me-1">Tokens:</span>
                            <span>{{listener?.tokens || 0}}</span>
                          </span>
                          <span>
                            <span class="fw-semibold me-1">Token Spent:</span>
                           <span>{{listener?.spent_tokens || 0}}</span>
                          </span>
                        </p>
                        <p class="user_status">
                          <span class="status {{(listener?.listener_status ? 'active': 'inactive')}}">
                          <span class="badge"></span>
                            {{listener?.listener_status === true ? ('active' | translate) : ('inactive' | translate)}}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="logo_seller_main">
                      <div class="brands text-capitalize">
                        <button type="button" class="btn-icon float-end" placement="bottom right"
                                containerClass="custom-popover no-arrow"
                                [outsideClick]="true" container="body" [adaptivePosition]="false"
                                [popover]="popTemplateMobile">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ng-template #popTemplateMobile>
                          <div class="item justify-content-between pe-0">
                            <span>{{'status' | translate}}</span>
                            <div class="text-start d-flex align-items-center">
                              <label class="action-switch-btn me-2 m-0" (click)="listener?.listener_status ? deactivateMember($event, listener) : activateMember($event, listener)">
                                <input type="checkbox" [checked]="listener?.listener_status">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div class="item justify-content-between pe-0">
                            <span>Action</span>
                            <div class="text-start d-flex align-items-center">
                              <label class="action-switch-btn me-2 m-0" (click)="listener?.is_blocked_by_admin ? blockUser($event, listener) : unBlockUser($event, listener)">
                                <input type="checkbox" [checked]="listener?.is_blocked_by_admin">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul *ngIf="listeners.length <= 0">
                  <li>
                    <div class="seller_details">
                      <div class="seller_text">
                        <h6 class="mb-0">No data found</h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 custom-pagination">
        <pagination [boundaryLinks]="true" [rotate]="true" [totalItems]="dataCount"
                    [ngModel]="queryParams.page"
                    [itemsPerPage]="10" [maxSize]="5" (pageChanged)="changePage($event)"></pagination>
      </div>
    </div>
    <frontend-bottom-header-admin></frontend-bottom-header-admin>
  </div>
</div>
