<div class="main_wrapper">
  <section class="main_content">
    <div class="verify_header">
      <div class="skit_details">
        <div class="skit_back">
          <img src="assets/images/back_ic.svg"/>
          <span>Back</span>
        </div>
        <div class="pend_verification"><p>Verify artist’s profile</p></div>
        <div class="live_show_upload_btn">
          <button class="deny_btn">Deny</button>
          <button class="verify_btn">Verify</button>
        </div>
      </div>
    </div>
    <div class="information_section">
      <div class="row justify-content">
        <div class="info_main">
          <h1 class="web_heading">General information</h1>
          <h1 class="for_mobile">Verify artist’s profile</h1>
          <div class="info_content">
            <h1>Category</h1>
            <p>Musician</p>
          </div>
          <div class="info_content">
            <h1>Artist name</h1>
            <p>Artist name goes here</p>
          </div>
          <div class="artist_photo">
            <h1>Artist photo</h1>
            <img src="assets/images/avatars_2.png"/>
          </div>
          <div class="social_ic">
            <div class="soc_main">
              <h1>Instagram</h1>
              <div class="insta_main">
                <img src="assets/images/insta_svg.svg"/>
                <span>Connect to Instagram</span>
              </div>
            </div>
            <div class="soc_main">
              <h1>Twitter</h1>
              <div class="insta_main">
                <img src="assets/images/ints.svg"/>
                <span>Connect to Twitter</span>
              </div>
            </div>
          </div>
        </div>
        <div class="documents_mian">
          <h1>Documentation</h1>
          <div class="passports">
            <p>International passport</p>
            <div class="upload_img">
              <img src="assets/images/image_9.png"/>
              <input type="file" id="myfile" name="myfile">
              <span><img src="assets/images/uplo.png"/></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mob_btn">
      <div class="mobile_btn">
        <button class="deny">Deny</button>
        <button class="verify">Verify</button>
      </div>
    </div>
  </section>
</div>
