import {Component, EventEmitter, Input, Output} from '@angular/core';
// import {User} from "@frontend/data-models";
import {AuthState, getLoggedInUser, LogoutUser} from "@frontend/auth-store";
import {LocalstorageService} from "@frontend/app-config";
import {select, Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {AdminState} from "@frontend/admin-store";
import {Subject, takeUntil } from 'rxjs';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'frontend-verify-header-admin',
  templateUrl: './verify-header.component.html',
  styleUrls: ['./verify-header.component.scss'],
})
export class VerifyHeaderComponent {

  @Input() currentUser: any;
  unsubscriber = new Subject();
  @Output() eventCurrentUser = new EventEmitter();

  constructor(
    private router: Router,
    private authStore: Store<AuthState>,
    private ls: LocalstorageService,
    private adminStore: Store<AdminState>,
    private authSocialService: SocialAuthService,
  ) {
    this.subscribeToStore()
  }

  ngOnInit(): void {
    this.subscribeToStore()

  }
  subscribeToStore() {

    this.adminStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
        if (user) {
          this.currentUser = user;
          this.eventCurrentUser.emit(this.currentUser);
        }
      })
  }

  logout() {
    this.ls.clearAllLocalStorage();
    this.authSocialService.signOut().catch((error => console.log('--------------===', error)));
    this.authStore.dispatch(LogoutUser());
    this.router.navigate(['/sign-in']);
  }
}
