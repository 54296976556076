/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Chart, ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import { default as Annotation } from 'chartjs-plugin-annotation';

@Component({
  selector: 'frontend-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges {
  @Input() values: number[];
  @Input() keys: string[];
  @Input() isShowPoints: boolean;

  private newLabel? = 'New label';
  public lineChartData: ChartConfiguration['data'];
  constructor(private cdr: ChangeDetectorRef) {
    Chart.register(Annotation);
  }
  ngOnChanges(changes: SimpleChanges): void {

    this.cdr.detectChanges();
    this.lineChartData = {
      datasets: [
        {
          data: changes?.values?.currentValue,
          label: 'Series A',
          backgroundColor: 'transparent',
          borderColor: '#000',
          pointBackgroundColor: '#000',
          pointBorderColor: '#000',
          pointHoverBackgroundColor: '#000',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          fill: 'origin',
          ...(this.isShowPoints ? {pointStyle: false} : {}),
        },
      ],
      labels: changes?.keys?.currentValue,
    };
  }
  ngOnInit(): void {
    console.log();
  }
  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      y: {
        display: true,
        beginAtZero: true,
        position: 'left',
        border: {
          display: true,
          color: '#D1D6DC',
        },
        grid: {
          display: false
        },
        ticks: {
          display: false
        }
      },
      y1: {
        display: false,
        position: 'right',
        grid: {
          color: 'rgba(255,0,0,0.3)',
        },
        ticks: {
          color: 'rgba(255,0,0,0.3)',
        },
      },
      x: {
        beginAtZero: true,
        position:'bottom',
        border: {
          display: true,
          color: '#D1D6DC',
        },
        grid: {
          display: false
        },
      },
    },

    plugins: {
      legend: { display: false },
      annotation: {
        annotations: [
          {
            type: 'line',
            scaleID: 'x',
            value: 'March',
            borderColor: 'orange',
            borderWidth: 2,
            display: false,
            label: {
              display: false,
              position: 'center',
              color: 'orange',
              content: 'LineAnno',
              font: {
                weight: 'bold',
              },
            },
          },
        ],
      },
    },
  };

  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  private static generateNumber(i: number): number {
    return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  }

  public randomize(): void {
    for (let i = 0; i < this.lineChartData.datasets.length; i++) {
      for (let j = 0; j < this.lineChartData.datasets[i].data.length; j++) {
        this.lineChartData.datasets[i].data[j] =
          ChartComponent.generateNumber(i);
      }
    }
    this.chart?.update();
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    // eslint-disable-next-line @typescript-eslint/ban-types
    active?: {}[];
  }): void {
    console.log('');
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    // eslint-disable-next-line @typescript-eslint/ban-types
    active?: {}[];
  }): void {
  }

  public hideOne(): void {
    const isHidden = this.chart?.isDatasetHidden(1);
    this.chart?.hideDataset(1, !isHidden);
  }

  public pushOne(): void {
    this.lineChartData.datasets.forEach((x, i) => {
      const num = ChartComponent.generateNumber(i);
      x.data.push(num);
    });
    this.lineChartData?.labels?.push(
      `Label ${this.lineChartData.labels.length}`
    );

    this.chart?.update();
  }

  public changeColor(): void {
    this.lineChartData.datasets[2].borderColor = 'green';
    this.lineChartData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

    this.chart?.update();
  }

  public changeLabel(): void {
    const tmp = this.newLabel;
    this.newLabel = this.lineChartData.datasets[2].label;
    this.lineChartData.datasets[2].label = tmp;

    this.chart?.update();
  }
}
