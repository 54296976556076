import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {map} from 'rxjs';
import {AuthState, getLoggedInUser} from "@frontend/auth-store";
import {select, Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authStore: Store<AuthState>,
    private toastr: ToastrService,
) {
  }

  canActivate() {
    return this.authStore.pipe(select(getLoggedInUser)).pipe(
      map((currentUser: any) => {
        if (!currentUser) {
          this.router.navigate(['/sign-in']);
          this.toastr.error("Your session has expired. Please log in again.")
          return false;
        }
        return true;
      })
    )
  }

}
