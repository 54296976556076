import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {SideBarComponent} from "./components/side-bar/side-bar.component";
import {VerifyHeaderComponent} from "./components/verify-header/verify-header.component";
import {BottomHeaderComponent} from "./components/bottom-header/bottom-header.component";
import {RouterModule} from "@angular/router";
import { BsDropdownModule,BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import {ChartComponent} from "./components/chart/chart.component";
import {NgChartsModule} from "ng2-charts";
import {TooltipModule} from "ngx-bootstrap/tooltip";

@NgModule({
  declarations: [SideBarComponent, VerifyHeaderComponent, BottomHeaderComponent, ChartComponent],
    imports: [CommonModule, RouterModule, BsDropdownModule, NgChartsModule, TooltipModule],
  exports: [SideBarComponent, VerifyHeaderComponent, BottomHeaderComponent, ChartComponent],
  providers: [BsDropdownConfig],

})
export class CommonComponentModule {
}
