import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {catchError, map, of, switchMap} from 'rxjs';
import {LoaderService} from '@frontend/app-config';
import {AdminService} from '../services/admin.service';
import {
  GetArtistProfileSuccess,
  GetArtistProfileError,
  GetArtistProfile,
  GetAllArtistSuccess,
  GetAllArtistError,
  GetAllArtist,
  AcceptRejectArtistProfile,
  AcceptRejectArtistProfileSuccess,
  AcceptRejectArtistProfileError,
  UpdateArtist,
  UpdateArtistSuccess,
  UpdateArtistError,
  GetAllRadioStations,
  GetAllRadioStationsSuccess,
  GetAllRadioStationsError,
  DeleteRadioStation,
  DeleteRadioStationSuccess,
  DeleteRadioStationError,
  AddUpdateRadioStation,
  AddUpdateRadioStationSuccess,
  AddUpdateRadioStationError,
  GetRadioStationById,
  GetRadioStationByIdSuccess,
  GetRadioStationByIdError,
  GetAllGenres,
  GetAllGenresSuccess,
  GetAllGenresError,
  DeleteGenre,
  DeleteGenreSuccess,
  DeleteGenreError,
  GetAllCategory,
  GetAllCategorySuccess,
  GetAllCategoryError,
  DeleteCategory,
  DeleteCategorySuccess,
  DeleteCategoryError,
  AddUpdateGenre,
  AddUpdateGenreSuccess,
  AddUpdateGenreError,
  AddUpdateCategory,
  AddUpdateCategorySuccess,
  AddUpdateCategoryError,
  GetMusicList,
  GetMusicListError,
  GetMusicListSuccess,
  GetMusicTrackList,
  GetMusicTrackListError,
  GetMusicTrackListSuccess,
  GetPodcastEpisodeList,
  GetPodcastEpisodeListError,
  GetPodcastEpisodeListSuccess,
  GetPodcastList,
  GetPodcastListError,
  GetPodcastListSuccess,
  GetSkitsList,
  GetSkitsListError,
  GetSkitsListSuccess,
  GetAds,
  GetAdsError,
  GetAdsSuccess,
  GetAdsAudience,
  GetAdsAudienceSuccess,
  GetAdsAudienceError,
  GetAd,
  GetAdSuccess,
  GetAdError,
  GetAdAudience,
  GetAdAudienceSuccess,
  GetAdAudienceError,
  CreateAdAudience,
  CreateAdAudienceSuccess,
  CreateAdAudienceError,
  CreateAd,
  CreateAdSuccess,
  CreateAdError,
  UpdateAd,
  UpdateAdSuccess,
  UpdateAdError,
  UpdateAdAudience,
  UpdateAdAudienceSuccess,
  UpdateAdAudienceError,
  DeleteAdAudience,
  DeleteAdAudienceSuccess,
  DeleteAdAudienceError,
  DeleteAd,
  DeleteAdSuccess,
  DeleteAdError,
  AddUpdatePlans,
  AddUpdatePlansSuccess,
  AddUpdatePlansError,
  GetAllPlans,
  GetAllPlansSuccess,
  GetAllPlansError,
  DeletePlan,
  DeletePlanSuccess,
  DeletePlanError,
  GetTokenCostDetail,
  GetTokenCostDetailSuccess,
  GetTokenCostDetailError,
  CreateTokenCost,
  CreateTokenCostSuccess,
  CreateTokenCostError,
  UpdateTokenCost,
  UpdateTokenCostSuccess,
  UpdateTokenCostError,
  DeleteTokenCost,
  DeleteTokenCostSuccess,
  DeleteTokenCostError,
  GetAdminDashboard,
  GetAdminDashboardSuccess,
  GetAdminDashboardError,
  UpdatePayoutStatus,
  UpdatePayoutStatusSuccess,
  UpdatePayoutStatusError,
  GetPayoutListError,
  GetPayoutListSuccess,
  GetPayoutList,
  GetPlatformCommissionList,
  GetPlatformCommissionListSuccess,
  GetPlatformCommissionListError,
  UpdatePlatformCommission,
  UpdatePlatformCommissionSuccess,
  UpdatePlatformCommissionError,
  GetAllListener,
  GetAllListenerSuccess,
  GetAllListenerError, UpdateListener, UpdateListenerSuccess, UpdateListenerError,
  SendTokensToArtists,
  SendTokensToArtistsSuccess,
  SendTokensToArtistsError,
  StreamTokenDetails,
  StreamTokenDetailsSuccess,
  StreamTokenDetailsError,
  GetArtistTokensList,
  GetArtistTokensListSuccess,
  GetArtistTokensListError,
  AddArtistTokens,
  AddArtistTokensSuccess,
  AddArtistTokensError,
} from './admin.actions';

@Injectable()
export class AdminEffects {
  constructor(
    private actions$: Actions,
    private adminService: AdminService,
    private loaderService: LoaderService
  ) {
  }

  GetAdminDashboard$ = createEffect(() => this.actions$.pipe(
    ofType(GetAdminDashboard),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getAdminDashboard(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAdminDashboardSuccess({dashboard: resp.data});
          }
          return GetAdminDashboardError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetAdminDashboardError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  DeletePlan$ = createEffect(() => this.actions$.pipe(
    ofType(DeletePlan),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.deletePlan(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return DeletePlanSuccess({success: resp.message});
          }
          return DeletePlanError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DeletePlanError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  GetAllPlans$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllPlans),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getPlans(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAllPlansSuccess({planlist: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return GetAllPlansError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetAllPlansError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  )
);

  AddUpdatePlans$ = createEffect(() => this.actions$.pipe(
    ofType(AddUpdatePlans),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.addPlan(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return AddUpdatePlansSuccess({plan: resp.data});
          }
          return AddUpdatePlansError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(AddUpdatePlansError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  getProfile$ = createEffect(() => this.actions$.pipe(
      ofType(GetArtistProfile),
      switchMap((action) => {
        this.loaderService.show();
        return this.adminService.getArtistProfile(action.id, action.query).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return GetArtistProfileSuccess({artist: resp.data});
            }
            return GetArtistProfileError({error: this.loaderService.getErrorMessage(resp)});
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(GetArtistProfileError({error: this.loaderService.getErrorMessage(error)}));
          })
        );
      })
    )
  );

  getAllArtist$ = createEffect(() => this.actions$.pipe(
      ofType(GetAllArtist),
      switchMap((action) => {
        this.loaderService.show();
        return this.adminService.getAllArtists(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return GetAllArtistSuccess({artists: resp.data || {data: [], count: {total: 0, page: 0}}});
            }
            return GetAllArtistError({error: this.loaderService.getErrorMessage(resp)});
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(GetAllArtistError({error: this.loaderService.getErrorMessage(error)}));
          })
        );
      })
    )
  );

  acceptReject$ = createEffect(() => this.actions$.pipe(
      ofType(AcceptRejectArtistProfile),
      switchMap((action) => {
        this.loaderService.show();
        return this.adminService.acceptRejectArtistProfile(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return AcceptRejectArtistProfileSuccess({success: resp.message});
            }
            return AcceptRejectArtistProfileError({error: this.loaderService.getErrorMessage(resp)});
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(AcceptRejectArtistProfileError({error: this.loaderService.getErrorMessage(error)}));
          })
        );
      })
    )
  );

  updateArtist$ = createEffect(() => this.actions$.pipe(
      ofType(UpdateArtist),
      switchMap((action) => {
        this.loaderService.show();
        return this.adminService.updateArtist(action.body).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return UpdateArtistSuccess({success: resp.message});
            }
            return UpdateArtistError({error: this.loaderService.getErrorMessage(resp)});
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(UpdateArtistError({error: this.loaderService.getErrorMessage(error)}));
          })
        );
      })
    )
  );

  getAllRadioStations$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllRadioStations),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getAllRadioStations(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAllRadioStationsSuccess({radio_stations: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return GetAllRadioStationsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetAllRadioStationsError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  getRadioStationById$ = createEffect(() => this.actions$.pipe(
    ofType(GetRadioStationById),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getRadioStationById(action.radio_station_id, action.query).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetRadioStationByIdSuccess({radio_station: resp.data});
          }
          return GetRadioStationByIdError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetRadioStationByIdError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  addUpdateRadioStation$ = createEffect(() => this.actions$.pipe(
    ofType(AddUpdateRadioStation),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.addUpdateRadioStation(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return AddUpdateRadioStationSuccess({radio_station: resp.data});
          }
          return AddUpdateRadioStationError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(AddUpdateRadioStationError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  deleteRadioStation$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteRadioStation),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.deleteRadioStation(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return DeleteRadioStationSuccess({success: resp.message});
          }
          return DeleteRadioStationError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DeleteRadioStationError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getAllGenres$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllGenres),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getAllGenres(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAllGenresSuccess({genres: resp.data});
          }
          return GetAllGenresError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetAllGenresError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  addUpdateGenre$ = createEffect(() => this.actions$.pipe(
    ofType(AddUpdateGenre),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.addUpdateGenre(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return AddUpdateGenreSuccess({genres: resp.data});
          }
          return AddUpdateGenreError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(AddUpdateGenreError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  deleteGenre$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteGenre),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.deleteGenre(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return DeleteGenreSuccess({success: resp.message});
          }
          return DeleteGenreError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DeleteGenreError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  addUpdateCategory$ = createEffect(() => this.actions$.pipe(
    ofType(AddUpdateCategory),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.addUpdateCategory(action.body, action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return AddUpdateCategorySuccess({categories: resp.data});
          }
          return AddUpdateCategoryError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(AddUpdateGenreError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  getAllCategory$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllCategory),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getAllCategories(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAllCategorySuccess({categories: resp.data});
          }
          return GetAllCategoryError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetAllCategoryError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  deleteCategory$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteCategory),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.deleteCategories(action.id, action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return DeleteCategorySuccess({success: resp.message});
          }
          return DeleteCategoryError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DeleteCategoryError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getMusics$ = createEffect(() => this.actions$.pipe(
    ofType(GetMusicList),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getMusics(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetMusicListSuccess({musics: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return GetMusicListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetMusicListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  getMusicTrack$ = createEffect(() => this.actions$.pipe(
    ofType(GetMusicTrackList),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getMusicTracks(action.music_id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetMusicTrackListSuccess({tracks: resp.data?.data || []});
          }
          return GetMusicTrackListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetMusicTrackListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  getPodcasts$ = createEffect(() => this.actions$.pipe(
    ofType(GetPodcastList),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getPodcasts(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetPodcastListSuccess({podcasts: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return GetPodcastListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetPodcastListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  getPodcastEpisode$ = createEffect(() => this.actions$.pipe(
    ofType(GetPodcastEpisodeList),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getPodcastEpisodes(action.podcast_id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetPodcastEpisodeListSuccess({episodes: resp.data?.data || []});
          }
          return GetPodcastEpisodeListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetPodcastEpisodeListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  getSkits$ = createEffect(() => this.actions$.pipe(
    ofType(GetSkitsList),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getSkits(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetSkitsListSuccess({skits: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return GetSkitsListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetSkitsListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  // ADS //

  adsList$ = createEffect(() => this.actions$.pipe(
    ofType(GetAds),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getAds(action.params).pipe(
      map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAdsSuccess({ads: resp.data});
          }
          return GetAdsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetAdsError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  adsAudienceList$ = createEffect(() => this.actions$.pipe(
    ofType(GetAdsAudience),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getAdsAudience(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAdsAudienceSuccess({adsAudience: resp.data});
          }
          return GetAdsAudienceError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetAdsAudienceError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  getAd$ = createEffect(() => this.actions$.pipe(
    ofType(GetAd),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getAd(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAdSuccess({ad: resp.data});
          }
          return GetAdError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetAdError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  getAdAudience$ = createEffect(() => this.actions$.pipe(
    ofType(GetAdAudience),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getAdAudience(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAdAudienceSuccess({adAudience: resp.data});
          }
          return GetAdAudienceError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetAdAudienceError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  createAdAudience$ = createEffect(() => this.actions$.pipe(
    ofType(CreateAdAudience),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.addAdAudience(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return CreateAdAudienceSuccess({adAudience: resp.data});
          }
          return CreateAdAudienceError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(CreateAdAudienceError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  createAd$ = createEffect(() => this.actions$.pipe(
    ofType(CreateAd),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.addAd(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return CreateAdSuccess();
          }
          return CreateAdError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(CreateAdError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  updateAd$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateAd),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.updateAd(action.id, action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return UpdateAdSuccess();
          }
          return UpdateAdError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(UpdateAdError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  updateAdAudience$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateAdAudience),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.updateAdAudience(action.id, action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return UpdateAdAudienceSuccess({adAudience: resp.data});
          }
          return UpdateAdAudienceError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(UpdateAdAudienceError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  deleteAdAudience$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteAdAudience),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.deleteAdAudience(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return DeleteAdAudienceSuccess();
          }
          return DeleteAdAudienceError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DeleteAdAudienceError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  deleteAd$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteAd),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.deleteAd(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return DeleteAdSuccess();
          }
          return DeleteAdError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DeleteAdError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  tokenCostDetail$ = createEffect(() => this.actions$.pipe(
    ofType(GetTokenCostDetail),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getTokenCost(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetTokenCostDetailSuccess({tokenCostDetail: resp.data});
          }
          return GetTokenCostDetailError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetTokenCostDetailError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  createTokenCost$ = createEffect(() => this.actions$.pipe(
    ofType(CreateTokenCost),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.addTokenCost(action.body, false).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return CreateTokenCostSuccess();
          }
          return CreateTokenCostError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(CreateTokenCostError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  updateTokenCost$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateTokenCost),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.addTokenCost(action.body, true).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return UpdateTokenCostSuccess();
          }
          return UpdateTokenCostError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(UpdateTokenCostError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  deleteTokenCost$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteTokenCost),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.deleteTokenCost(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return DeleteTokenCostSuccess();
          }
          return DeleteTokenCostError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DeleteTokenCostError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  // Finance tab
  getPayout$ = createEffect(() => this.actions$.pipe(
    ofType(GetPayoutList),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getPayout(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetPayoutListSuccess({payout: resp.data});
          }
          return GetPayoutListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetPayoutListError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  updatePayoutStatus$ = createEffect(() => this.actions$.pipe(
    ofType(UpdatePayoutStatus),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.updatePayoutStatus(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return UpdatePayoutStatusSuccess({payout: resp.data});
          }
          return UpdatePayoutStatusError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(UpdatePayoutStatusError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  // Platform commission tab
  getPlatformCommission$ = createEffect(() => this.actions$.pipe(
    ofType(GetPlatformCommissionList),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getPlatformCommissions(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetPlatformCommissionListSuccess({platform_commissions: resp.data});
          }
          return GetPlatformCommissionListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetPlatformCommissionListError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  updatePlatformCommission$ = createEffect(() => this.actions$.pipe(
    ofType(UpdatePlatformCommission),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.updatePlatformCommission(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return UpdatePlatformCommissionSuccess({platform_commissions: resp.data});
          }
          return UpdatePlatformCommissionError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(UpdatePlatformCommissionError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getAllListener$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllListener),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getAllListeners(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetAllListenerSuccess({listeners: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return GetAllListenerError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetAllListenerError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
    )
  );

  updateListener$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateListener),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.updateListener(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return UpdateListenerSuccess({listener: resp.data});
          }
          return UpdateListenerError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(UpdateListenerError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  SendTokensToArtists$ = createEffect(() => this.actions$.pipe(
    ofType(SendTokensToArtists),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.sendTokensToArtist(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return SendTokensToArtistsSuccess({tokensData: resp});
          }
          return SendTokensToArtistsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(UpdateListenerError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  StreamTokenDetails$ = createEffect(() => this.actions$.pipe(
    ofType(StreamTokenDetails),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getStreamTokenDetails(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return StreamTokenDetailsSuccess({streamTokenDetails: resp});
          }
          return StreamTokenDetailsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(StreamTokenDetailsError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  ArtistTokens$ = createEffect(() => this.actions$.pipe(
    ofType(GetArtistTokensList),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getArtistTokensList(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return GetArtistTokensListSuccess({artistTokens: resp});
          }
          return GetArtistTokensListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetArtistTokensListError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  AddArtistTokens$ = createEffect(() => this.actions$.pipe(
    ofType(AddArtistTokens),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.AddArtistTokens(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.success) {
            return AddArtistTokensSuccess({artistToken: resp});
          }
          return AddArtistTokensError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(AddArtistTokensError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))


// -------------------
}
