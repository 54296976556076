import {Component, OnInit, ViewChild} from '@angular/core';
import {CustomTableComponent} from "@frontend/shared";
import * as dayjs from 'dayjs';
import {Subject, takeUntil} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {
  AdminState,
  getPayout,
  GetPayoutList,
  UpdatePayoutStatus
} from "@frontend/admin-store";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {FinanceSendTokenArtistModalComponent} from "./finance-send-token-artist-modal/finance-send-token-artist-modal.component";

@Component({
  selector: 'frontend-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss'],
})
export class FinanceComponent implements OnInit {
  currentUser: any;
  submitted = false;
  isMenuShow = false;
  unsubscriber = new Subject();
  allData: any;
  filter: UntypedFormGroup;
  totalMonth: any[] = [
    {
      name: 'January',
      id: '01'
    },
    {
      name: 'February',
      id: '02'
    },
    {
      name: 'March',
      id: '03'
    },
    {
      name: 'April',
      id: '04'
    },
    {
      name: 'May',
      id: '05'
    },
    {
      name: 'June',
      id: '06'
    },
    {
      name: 'July',
      id: '07'
    },
    {
      name: 'August',
      id: '08'
    },
    {
      name: 'September',
      id: '09'
    },
    {
      name: 'October',
      id: '10'
    },
    {
      name: 'November',
      id: '11'
    },
    {
      name: 'December',
      id: '12'
    },
  ];

  currMonth: any[] = []
  roleType: any[] = [
    {
      name: 'Artist',
      id: 'artist'
    },
    {
      name: 'User',
      id: 'user'
    },
  ]

  month: any[] = [
    {
      name: 'Current Week',
      id: `${dayjs().startOf('week').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Current Month',
      id: `${dayjs().startOf('month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 30 days',
      id: `${dayjs().subtract(30, 'days').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 6 month',
      id: `${dayjs().subtract(6, 'month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 12 Months',
      id: `${dayjs().subtract(12, 'month').format('YYYY-MM-DD')}/${dayjs().format('YYYY-MM-DD')}`
    },
  ];
  customDatatableSettings: any = {};
  transactionDate: any = [];
  dates: any;
  selectedFilter: string | number = '';
  selectedRoleTypeFilter: string = '';
  walletData: any;
  monthlyTransactionList: any = []
  currentPage = 1;
  itemsPerPage = 10;
  scheduleTypes = 'Weekly';
  scheduleMenu: any;
  payoutTransactionDetail: any = [];
  timeout: any = null;
  currencyRate: any;
  searchText: any = '';

  dayName: any[] = [
    'Sunday',
    'Monday',
    'TuesDay',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  status: any[] = [
    {
      id: "ON HOLD",
      name: "On Hold"
    },
    {
      id: "PAID",
      name: "Paid"
    }
  ]

  @ViewChild('customTableComponent', {static: true}) customTableComponent: CustomTableComponent;

  constructor(
    private adminStore: Store<AdminState>,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
  ) {
    this.filter = this.formBuilder.group({
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
      monthRange: [null, [Validators.required]],
      roleType: ['artist', [Validators.required]],
    })

    this.subscribeToStore()
  }

  ngOnInit(): void {
    this.customDatatableSettings = {
      paging: true,
      withPage: true,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      orderColumns: ['full_name', 'product', 'createdAt', 'tokens', 'amount', 'status'],
      order: [[1, 'desc']],
      searching: false,
    }
  }

  subscribeToStore() {
    this.adminStore.pipe(select(getPayout))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(payout => {
        if (payout) {
          this.payoutTransactionDetail = payout;
          let groupViceTransaction: any = [];
          this.payoutTransactionDetail?.data?.forEach((item: any) => {
            const formattedDate = dayjs(item.createdAt).startOf('month').format('MMMM YYYY')
            const currentList = groupViceTransaction[formattedDate] ?? []
            Object.assign(groupViceTransaction, {[formattedDate]: [...currentList, item]})
          })

          const groupViceTransactionValue: any = Object.values(groupViceTransaction);
          const groupViceTransactionIndex: any = Object.keys(groupViceTransaction);
          this.monthlyTransactionList = [];
          for (let i = 0; i < groupViceTransactionValue.length; i++) {
            this.monthlyTransactionList.push({value: groupViceTransactionIndex[i], type: 'group'});

            for (let j = 0; j < groupViceTransactionValue[i].length; j++) {
              this.monthlyTransactionList.push(groupViceTransactionValue[i][j]);
            }
          }
          this.monthlyTransactionList = this.monthlyTransactionList.map((item: any) => ({
            ...item,
            status: item.status
          }))

          if (this.customTableComponent?.ajaxCallback) {
            this.customTableComponent?.ajaxCallback({
              recordsTotal: this.payoutTransactionDetail.count.total,
              recordsFiltered: this.payoutTransactionDetail.count.total,
              data: []
            })

            setTimeout(() => {
              this.customTableComponent?.reAdjustColumnsInTable();
            }, 500);
          }
        } else {
          this.monthlyTransactionList = []
          this.payoutTransactionDetail = []
        }
      })
  }

  getAllWalletTransaction = (page = 0, perPage = 10, search = '', order = {
    filterBy: 'status',
    filterType: 'dec'
  }) => {
    this.adminStore.dispatch(GetPayoutList({
      params: {
        page, limit: perPage,
        search,
        ...order,
        monthRange: this.filter.value.monthRange,
        roleType: this.filter.value.roleType
      }
    }))
  }

  filterWalletData(page: any = {}) {
    if (page?.page) {
      this.currentPage = page?.page;
    }
    this.adminStore.dispatch(GetPayoutList({
      params: {
        page: this.currentPage - 1,
        limit: this.itemsPerPage,
        search: this.searchText,
        monthRange: this.filter.value.monthRange,
        roleType: this.filter.value.roleType
      }
    }))
  }

  filterRoleTypeData(page: any = {}) {
    if (page?.page) {
      this.currentPage = page?.page;
    }
    this.adminStore.dispatch(GetPayoutList({
      params: {
        page: this.currentPage - 1,
        limit: this.itemsPerPage,
        search: this.searchText,
        monthRange: this.filter.value.monthRange,
        roleType: this.filter.value.roleType
      }
    }))
    this.selectedRoleTypeFilter = this.filter.value.roleType
  }

  sendTokens() {
    const modelRef: BsModalRef = this.modalService.show(
      FinanceSendTokenArtistModalComponent,
      {
        class: 'modal-dialog-centered send-fan-modal modal-small',

      }
    );

    modelRef.content.closeEvent.subscribe((data: any) => {
      if (data) {
        this.adminStore.dispatch(GetPayoutList({
          params: {
            page: this.currentPage - 1,
            limit: this.itemsPerPage,
            search: this.searchText,
            monthRange: this.filter.value.monthRange,
            roleType: this.filter.value.roleType
          }
        }))
      }
    });

    console.log('modelRef', modelRef)
    return;
  }

  search(event: any) {
    if (event) {
      let walletSearch = event.target.value;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        walletSearch = walletSearch.trim();
        this.searchText = walletSearch;
        this.getAllWalletTransaction(undefined, undefined, walletSearch, undefined)
      }, 1500);
    }
  }

  statusData( id: any) {
    this.adminStore.dispatch(UpdatePayoutStatus({
      params: {
        id,
        status: 'PAID'
      }
    }))

  }

  checkData() {
    if (this.currentUser.admin_role !== 'MANAGEMENT' && this.currentUser.admin_role !== 'IT') {
      return this.currentUser.permissions.some((item: any) => item.menu === 'finance' && item.permissionType.some((per: any) => per === 'write'));
    } else {
      return this.currentUser.admin_role === 'MANAGEMENT' || this.currentUser.admin_role === 'IT';
    }
  }

  getCurrentUser(event: any) {
    this.currentUser = event;
  }

  redirectBack() {
    history.back();
  }
}
