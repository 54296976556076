<div class="body_bg">
  <div class="main_wrapper">
    <frontend-verify-header-admin></frontend-verify-header-admin>
    <frontend-side-bar-admin></frontend-side-bar-admin>
    <div class="main_content">
      <div class="dashboard_user_score">
          <h1>Hi, {{currentUser.first_name}} {{currentUser.last_name}}!</h1>
          <h4>Important review information</h4>
          <div class="row">
<!--            <div class="col-md-4">-->
<!--                <div class="review_info">-->
<!--                  <div class="verifiation_text">-->
<!--                    <div class="icon"> <img src="assets/images/verification.png"/></div>-->
<!--                    <p>Stores pending verification</p>-->
<!--                  </div>-->
<!--                  <div class="review_count">{{dashboard?.artistPendingVerification}}</div>-->
<!--                </div>-->
<!--            </div>-->

            <div class="col-xl-4">
              <div class="review_info">
                <div class="verifiation_text">
                  <div class="icon"> <img src="assets/images/artist_pen.png"/></div>
                  <p>Creators pending verification</p>
                </div>
                <div class="review_count">{{dashboard?.artistPendingVerification}}</div>
              </div>
          </div>

<!--          <div class="col-md-4">-->
<!--            <div class="review_info">-->
<!--              <div class="verifiation_text">-->
<!--                <div class="icon"> <img src="assets/images/pending_return.png"/></div>-->
<!--                <p>Pending return requests</p>-->
<!--              </div>-->
<!--              <div class="review_count">0</div>-->
<!--            </div>-->
<!--          </div>-->
      </div>

      <div class="plateform_Stat">
        <h1 class="stat_heading1">Your platform stats</h1>
        <div class="row">
          <div class="col-12">
            <div class="stat_performance">
              <div class="stat">
                <h1>Your platform stats</h1>
              </div>
<!--              <div class="select_box">-->
<!--                <select>-->
<!--                  <option>Last week</option>-->
<!--                  <option>Last week</option>-->
<!--                  <option>Last week</option>-->
<!--                </select>-->
<!--                <span> <img src="assets/images/down_select.png"/></span>-->
<!--              </div>-->
              <frontend-custom-select
                class="select_box"
                [selectOptions]="month"
                placeholder="Select date "
                [searchable]="false"
                [(selectedValue)]="monthRange"
                (optionSelected)="filterData($event)"
              >
              </frontend-custom-select>
            </div>
          </div>
        </div>
        <div class="row mt-">
          <div class="col-lg-7 col-md-12">

            <div class="revenue_stat">
              <div class="r-main">
                <div class="revenue_heading">
                  <span><img src="assets/images/rev.png"/></span>
                  <h4>Revenue</h4>
                </div>
                <div class="revenue_price">
                  <h1>${{dashboard?.revenue || 0.00}}</h1>
                </div>
                <div class="stat_graph">
<!--                  <img src="assets/images/stat_graph.png"/>-->
                  <frontend-chart
                    [values]="monthValues"
                    [keys]="monthKeys"
                    [isShowPoints]="true"
                  ></frontend-chart>
                </div>
              </div>
            </div>
            <div class="revenue_profit">
              <div class="revenue_totals">
                <p>Total revenue</p>
                <h6>${{dashboard?.revenue || 0.00}}</h6>
              </div>
              <div class="revenue_totals">
                <p>Net revenue</p>
                <h6>${{dashboard?.netRevenue || 0.00}}</h6>
              </div>
              <div class="revenue_totals">
                <p>Creators profit</p>
                <h6>$0.00</h6>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12">
            <div class="row  mt-25">
              <div class="col-6 col-xl-4">
                <div class="stat_artist">
                  <div class="stat_heading">
                    <img src="assets/images/artist.png"/>
                    <span>Creators</span>
                  </div>
                  <div class="artist_no">{{dashboard?.totalActiveArtist || 0}}</div>
                </div>
              </div>
              <div class="col-6 col-xl-4">
                <div class="stat_artist">
                  <div class="stat_heading">
                    <img src="assets/images/listners.png"/>
                    <span>Listeners</span>
                  </div>
                  <div class="artist_no">{{dashboard?.totalActiveListeners || 0}}</div>
                </div>
              </div>

              <div class="col-6 col-xl-4">
                <div class="stat_artist">
                  <div class="stat_heading">
                    <img src="assets/images/follower.png"/>
                    <span>Follows</span>
                  </div>
                  <div class="artist_no">{{dashboard?.followers || 0}}</div>
                </div>
              </div>
              <div class="col-6 col-xl-4">
                <div class="stat_artist">
                  <div class="stat_heading">
                    <img src="assets/images/follower.png"/>
                    <span>Fan clubs</span>
                  </div>
                  <div class="artist_no">{{dashboard?.fanClubs || 0}}</div>
                </div>
              </div>
              <div class="col-6 col-xl-4">
                <div class="stat_artist">
                  <div class="stat_heading">
                    <img src="assets/images/follower.png"/>
                    <span>Fanclub Subscribers</span>
                  </div>
                  <div class="artist_no">{{dashboard?.fans || 0}}</div>
                </div>
              </div>

              <div class="col-6 col-xl-4">
                <div class="stat_artist">
                  <div class="stat_heading">
                    <img src="assets/images/stream.png"/>
                    <span>Music Streams</span>
                  </div>
                  <div class="artist_no">{{dashboard?.streams || 0}}</div>
                </div>
              </div>
              <div class="col-6 col-xl-4">
                <div class="stat_artist">
                  <div class="stat_heading">
                    <img src="assets/images/stream.png"/>
                    <span>Podcast Streams</span>
                  </div>
                  <div class="artist_no">{{dashboard?.totalPodcastStream || 0}}</div>
                </div>
              </div>

              <div class="col-6 col-xl-4">
                <div class="stat_artist">
                  <div class="stat_heading">
                    <img src="assets/images/visit.png"/>
                    <span>Video Streams</span>
                  </div>
                  <div class="artist_no">{{dashboard?.videoviews || 0}}</div>
                </div>
              </div>
              <div class="col-6 col-xl-4">
                <div class="stat_artist">
                  <div class="stat_heading">
                    <img src="assets/images/visit.png"/>
                    <span>Visits</span>
                  </div>
                  <div class="artist_no">{{dashboard?.visits || 0}}</div>
                </div>
              </div>

              <div class="col-6 col-xl-4">
                <div class="stat_artist">
                  <div class="stat_heading">
                    <img src="assets/images/download.png"/>
                    <span>Downloads</span>
                  </div>
                  <div class="artist_no">{{dashboard?.downloads || 0}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

<!--        <nav>-->
<!--          <div class="nav nav-tabs1" id="nav-tab1" role="tablist">-->
<!--            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">All</button>-->
<!--            <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"> <span>Kwot </span>Store</button>-->
<!--            <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">B2B</button>-->
<!--            <button class="nav-link" id="nav-contact-tab-new" data-bs-toggle="tab" data-bs-target="#nav-contact1" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Kwot <span>Music</span> </button>-->
<!--          </div>-->
<!--        </nav>-->
<!--        <div class="tab-content" id="nav-tabContent">-->
<!--          <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">All</div>-->
<!--          <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">Kwot</div>-->
<!--          <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">B2B</div>-->
<!--          <div class="tab-pane fade" id="nav-contact1" role="tabpanel" aria-labelledby="nav-contact-tab-new">-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <frontend-bottom-header-admin></frontend-bottom-header-admin>
  </div>
</div>
