import {Component} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'frontend-bottom-header-admin',
  templateUrl: './bottom-header.component.html',
  styleUrls: ['./bottom-header.component.scss'],
})
export class BottomHeaderComponent {
  currentRoute: string;

  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter(t => t);
        if (url[0]) {
          this.currentRoute = url[0].indexOf('?') !== -1 ? url[0].substr(0, url[0].indexOf('?')): url[0];
        } else {
          this.currentRoute = 'dashboard';
        }
      }
    })
  }
}
