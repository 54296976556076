import {Component, Input} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {User} from "@frontend/data-models";
import {select, Store} from "@ngrx/store";
import {getLoggedInUser} from "@frontend/auth-store";
import {Subject, takeUntil} from "rxjs";
import {AdminState} from "@frontend/admin-store";

@Component({
  selector: 'frontend-side-bar-admin',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent {
  currentRoute: string;
  @Input() currentUser: User;
  unsubscriber = new Subject();

  constructor(
    private router: Router,
    private adminStore: Store<AdminState>,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter(t => t);
        if (url[0]) {
          this.currentRoute = url[0].indexOf('?') !== -1 ? url[0].substr(0, url[0].indexOf('?')): url[0];
        } else {
          this.currentRoute = 'dashboard';
        }

        console.log('this.currentRoute', this.currentRoute)
      }
    })

    this.adminStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
        if (user) {
          this.currentUser = user;
        }
      })
  }

  checkData(menu: any) {
    if (this.currentUser.admin_role !== 'MANAGEMENT' && this.currentUser.admin_role !== 'IT') {
      return this.currentUser?.permissions.some((item: any) => item.menu === menu);
    } else {
      return this.currentUser.admin_role === 'MANAGEMENT' || this.currentUser.admin_role === 'IT';
    }
  }
}
