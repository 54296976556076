import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'frontend-verify-pending',
  templateUrl: './verify-pending.component.html',
  styleUrls: ['./verify-pending.component.scss'],
})
export class VerifyPendingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
