import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AdminState} from './admin.reducer';

const getState = createFeatureSelector<AdminState>('admin');

export const getError = createSelector(getState, (state: AdminState) => state?.error);
export const getSuccess = createSelector(getState, (state: AdminState) => state?.success);
export const getAdminDashboard = createSelector(getState, (state: AdminState) => state?.dashboard);
export const getArtist = createSelector(getState, (state: AdminState) => state?.artist);
export const getAllArtist = createSelector(getState, (state: AdminState) => state?.artists);
export const getAllRadioStations = createSelector(getState, (state: AdminState) => state?.radio_stations);
export const getRadioStation = createSelector(getState, (state: AdminState) => state?.radio_station);
export const getAllGenres = createSelector(getState, (state: AdminState) => state?.genres);
export const getAllCategories = createSelector(getState, (state: AdminState) => state?.categories);
export const getMusics = createSelector(getState, (state: AdminState) => state?.musics);
export const getMusicTracks = createSelector(getState, (state: AdminState) => state?.tracks);
export const getPodcasts = createSelector(getState, (state: AdminState) => state?.podcasts);
export const getPodcastEpisodes = createSelector(getState, (state: AdminState) => state?.episodes);
export const getSkits = createSelector(getState, (state: AdminState) => state?.skits);
export const getAds = createSelector(getState, (state: AdminState) => state.ads);
export const getAdsAudience = createSelector(getState, (state: AdminState) => state.adsAudience);
export const getAd = createSelector(getState, (state: AdminState) => state.ad);
export const getAdAudience = createSelector(getState, (state: AdminState) => state.adAudience);
export const getAdsSuccess = createSelector(getState, (state: AdminState) => state.success);
export const getAdsError = createSelector(getState, (state: AdminState) => state.error);
export const getPlanRes = createSelector(getState,(state: AdminState) => state.plan);
export const getPlansData = createSelector(getState, (state: AdminState) => state.planlist);
export const getTokenCostData = createSelector(getState, (state: AdminState) => state.tokenCostDetail);
export const getPayout = createSelector(getState, (state: AdminState) => state.payout);
export const getPlatformCommissionData = createSelector(getState, (state: AdminState) => state.platform_commissions);
export const getAllListener = createSelector(getState, (state: AdminState) => state?.listeners);
export const getListener = createSelector(getState, (state: AdminState) => state.listener);
export const sendTokens = createSelector(getState, (state: AdminState) => state?.tokensData);
export const getArtistToken = createSelector(getState, (state: AdminState) => state?.streamTokenDetails);
export const getArtistTokenList = createSelector(getState, (state: AdminState) => state?.artistTokens);
