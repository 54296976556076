<!-- <frontend-verify-header></frontend-verify-header> -->
<div class="">
  <div class="main_wrapper">
    <!-- <frontend-side-bar></frontend-side-bar> -->
    <section class="main_content">
      <div class="skits_sections">
        <div class="skit_details">
          <div class="skit_back">
            <img src="assets/images/back_ic.svg" />
            <span>Back</span>
          </div>
          <div class="pend_verification"> <p>Pending verification</p></div>
          <div class="live_show_upload_btn">
           
          </div>
        </div>
      <div class="pending_verification_main">
        <div class="art_pending_verfi">
            <h1>Artists pending verification</h1>
            <div class="search_bar">
                <input type="text" placeholder="Search"/>
                <span><img src="assets/images/search_tab1.png"/></span>
            </div>
        </div>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="skit_main">
              <div class="skit_tables Desktop_view">
                <div class="table podcast_table">
                  <div class="table_heading_list">
                    <ul>
                      <li class="w-30">
                        <h1>Artist name</h1>
                        <span><img src="assets/images/up_down.png" /></span>
                      </li>
                      <li class="w-15">
                        <h1>Category</h1>
                        <span><img src="assets/images/up_down.png" /></span>
                      </li>
                      <li class="w-15">
                        <h1>Enrollment date</h1>
                        <span><img src="assets/images/up_down.png" /></span>
                      </li>
                      <li class="w-15">
                        <h1>Email</h1>
                        <span><img src="assets/images/up_down.png" /></span>
                      </li>
                    
                      <li class="w-10"></li>
                    </ul>
                  </div>
                  <div class="table_main_content">
                    <div class="table_first_list">
                      <ul class="list_ul">
                        <li class="w-30">
                          <div class="skit_details_here">
                            <div class="per">
                              <img src="assets/images/podcasts.png" />
                              <h2>Artist name</h2>
                            </div>
                          </div>
                        </li>
                        <li class="w-15">
                          <h2>Musician</h2>
                        </li>
                        <li class="w-15">
                          <h2>25/05/2021</h2>
                        </li>
                        <li class="w-15">
                          <h2>artist@email.com</h2>
                        </li>
                        <li class="w-15 text-right">
                            <img src="assets/images/right.png" />
                        </li>
                    
                      </ul>
                      <ul class="list_ul">
                        <li class="w-30">
                          <div class="skit_details_here">
                            <div class="per">
                              <img src="assets/images/podcasts.png" />
                              <h2>Artist name</h2>
                            </div>
                          </div>
                        </li>
                        <li class="w-15">
                          <h2>Comedian</h2>
                        </li>
                        <li class="w-15">
                          <h2>25/05/2021</h2>
                        </li>
                        <li class="w-15">
                          <h2>artist@email.com</h2>
                        </li>
                        <li class="w-15 text-right">
                            <img src="assets/images/right.png" />
                        </li>
                    
                      </ul>
                      <ul class="list_ul">
                        <li class="w-30">
                          <div class="skit_details_here">
                            <div class="per">
                              <img src="assets/images/podcasts.png" />
                              <h2>Artist name</h2>
                            </div>
                          </div>
                        </li>
                        <li class="w-15">
                          <h2>Podcaster</h2>
                        </li>
                        <li class="w-15">
                          <h2>25/05/2021</h2>
                        </li>
                        <li class="w-15">
                          <h2>artist@email.com</h2>
                        </li>
                        <li class="w-15 text-right">
                            <img src="assets/images/right.png" />
                        </li>
                    
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="podcast_mobile_view">
                <div class="product_title_here">
                  <div class="product_title_img">
                    <img src="assets/images/podcasts.png" />
                  </div>
                  <div class="product_title_details">
                    <h1>Podcast title goes here</h1>
                    <span>6 Jan 2022</span>
                  </div>
                  <div class="more_icon">
                    <img src="assets/images/Menu_dot.png" />
                  </div>
                </div>
                <div class="podcast_icons_list">
                  <ul>
                    <li>
                      <img src="assets/images/dashboard_music.png" />
                      <span>78</span>
                    </li>
                    <li>
                      <img src="assets/images/speaker.png" />
                      <span>43</span>
                    </li>
                    <li>
                      <img src="assets/images/downloads.png" />
                      <span>10</span>
                    </li>
                  </ul>
                </div>
                <div class="Episodes_main">
                  <div class="episode_detials">
                    <div class="episode_no">4. Episodes</div>
                    <div class="episode_arrow_uo">
                      <img src="assets/images/drop_down_Icon.png" />
                    </div>
                  </div>
                  <div class="sub_episode_list">
                    <ul>
                      <li>
                        <div class="episode_lists">
                          <h1>1 · Episode title goes here</h1>
                          <div class="episode_stream">
                            <p>Streams:<span>78</span></p>
                            <div class="diveder"></div>
                            <p>Listeners:<span>43</span></p>
                            <div class="diveder"></div>
                            <p>Downloads:<span>10</span></p>
                          </div>
                        </div>
                        <div class="episode_more">
                          <img src="assets/images/Menu_dot.png" />
                        </div>
                      </li>

                      <li>
                        <div class="episode_lists">
                          <h1>2 · Episode title goes here</h1>
                          <div class="episode_stream">
                            <p>Streams:<span>78</span></p>
                            <div class="diveder"></div>
                            <p>Listeners:<span>43</span></p>
                            <div class="diveder"></div>
                            <p>Downloads:<span>10</span></p>
                          </div>
                        </div>
                        <div class="episode_more">
                          <img src="assets/images/Menu_dot.png" />
                        </div>
                      </li>

                      <li>
                        <div class="episode_lists">
                          <h1>3 · Episode title goes here</h1>
                          <div class="episode_stream">
                            <p>Streams:<span>78</span></p>
                            <div class="diveder"></div>
                            <p>Listeners:<span>43</span></p>
                            <div class="diveder"></div>
                            <p>Downloads:<span>10</span></p>
                          </div>
                        </div>
                        <div class="episode_more">
                          <img src="assets/images/Menu_dot.png" />
                        </div>
                      </li>

                      <li>
                        <div class="episode_lists">
                          <h1>4 · Episode title goes here</h1>
                          <div class="episode_stream">
                            <p>Streams:<span>78</span></p>
                            <div class="diveder"></div>
                            <p>Listeners:<span>43</span></p>
                            <div class="diveder"></div>
                            <p>Downloads:<span>10</span></p>
                          </div>
                        </div>
                        <div class="episode_more">
                          <img src="assets/images/Menu_dot.png" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

        <div class="artist_verification_mobile">
            <ul>
                <li>
                    <div class="artist_user_profile">
                        <div class="user_img">
                            <img src="assets/images/user_photo.png" />
                        </div>
                        <div class="user_text">
                            <h1>Artist name</h1>
                            <p>artist@email.com</p>
                        </div>
                    </div>
                    <div class="date">
                        <p>25/05/2021</p>
                    </div>
                </li>
                <li>
                    <div class="artist_user_profile">
                        <div class="user_img">
                            <img src="assets/images/user_photo.png" />
                        </div>
                        <div class="user_text">
                            <h1>Artist name</h1>
                            <p>artist@email.com</p>
                        </div>
                    </div>
                    <div class="date">
                        <p>25/05/2021</p>
                    </div>
                </li>
            </ul>
        </div>

          </div>
        </div>
    </div>


      </div>

     
    </section>

   
  </div>
</div>


