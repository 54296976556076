<div class="model-content">
  <div class="modal-header pt-3">
    <button type="button" class="btn-close" (click)="modalRef.hide()">
      <img src="assets/images/Cross2.png" />
    </button>
  </div>
  <div class="modal-body">
    <h2>Send tokens</h2>
    <div class="mt-2 mb-3 flex text-dark">
<!--      <div>-->
        Total streams : <img class="img-wrapper w-auto mx-1" src="assets/images/token-coin.png" /><strong class="ms-1">{{tokenDetails?.totalStreamsCount || 0}}</strong>
<!--      </div>-->
<!--      <div>-->
        Total tokens : <img class="img-wrapper w-auto mx-1" src="assets/images/token-coin.png" /><strong class="ms-1">{{tokenDetails?.totalStreamWiseAmount || 0}}</strong>
<!--      </div>-->
    </div>

    <div class="container-fluid p-0">
      <div class="">
        <div class="finance-table">
          <div class="custom-table finances-table modal-card send-tokens">
            <frontend-custom-table
              #customTableComponent
              [customOptions]="customDatatableSettings"
              [tableHead]="tableHead"
              [ajaxFunction]="getAllWalletTransaction"
              [tableBody]="tableBody">
            </frontend-custom-table>

            <ng-template #tableHead>
              <thead>
              <tr>
                <td>
                  <p
                    class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                    {{'name' | translate}}
                  </p>
                </td>
                <td>
                  <p
                    class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                    {{'Streams' | translate}}
                  </p>
                </td>
                <td>
                  <p
                    class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                    {{'tokens' | translate}}
                  </p>
                </td>
              </tr>
              </thead>
            </ng-template>

            <ng-template #tableBody>
              <tbody *ngIf="tokenDetails?.artistStreams?.data.length <= 0">
              <tr>
                <td colspan="4">{{'no_data_found' | translate}}</td>
              </tr>
              </tbody>
              <tbody *ngIf="tokenDetails?.artistStreams?.data.length > 0">

              <ng-container *ngFor="let streamData of tokenDetails?.artistStreams?.data">
                  <tr>
                    <td>
                      <p class="mb-0 fs-16 store-content-details">
                        {{streamData?.artist?.artist_name}}
                      </p>
                    </td>
                    <td>
                      <p class="mb-0 fs-16 store-content-details">
                        {{streamData?.streams}}
                      </p>
                    </td>
                    <td>
                      <p class="mb-0 fs-16 store-content-details">
                        <img class="img-wrapper w-auto coin-icon" src="assets/images/token-coin.png" />
                        {{streamData?.tokens}}
                      </p>
                    </td>
                  </tr>
              </ng-container>
              </tbody>
            </ng-template>
          </div>
        </div>

        <div class="custom-pagination">
          <pagination [boundaryLinks]="true" [rotate]="true" [totalItems]="dataCount"
                      [ngModel]="queryParams.page"
                      [itemsPerPage]="5" [maxSize]="5" (pageChanged)="changePage($event)"></pagination>
        </div>
      </div>
    </div>
    <span class="mt-2 text-black">Are you sure you want to send tokens to artist?</span>

    <span *ngIf="tokenDetails?.totalStreamsCount <= 0 && tokenDetails?.totalStreamWiseAmount <= 0" class="token-note mt-2">*You have already sent tokens to all artists</span>
  </div>
  <div class="modal-footer">
<!--    <button (click)="sendTokens()" class="btn-verify mb-3">Send Token</button>-->
    <button (click)="sendTokens()" [disabled]="tokenDetails?.totalStreamsCount <= 0 && tokenDetails?.totalStreamWiseAmount <= 0" type="submit" [ngClass]="{'btn-verify': tokenDetails?.totalStreamsCount > 0 && tokenDetails?.totalStreamWiseAmount > 0,'btn-disabled': tokenDetails?.totalStreamsCount <= 0 && tokenDetails?.totalStreamWiseAmount <= 0}">
      Send Token
    </button>
    <button class="btn-cancel" (click)="modalRef.hide()">Cancel</button>

  </div>
</div>


