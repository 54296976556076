<div class="main_wrapper">
  <header class="top_bar">
    <div class="artist_name_type">
      <div class="artist_img">
        <img *ngIf="currentUser?.photo" [src]="currentUser?.photo" />
        <span class="text-bold">
          {{ currentUser?.first_name || '' }}{{" "}}{{ currentUser?.last_name || '' }}
        </span>
      </div>

      <div
        class="btn-group left_dp"
        dropdown
        #dropdown="bs-dropdown"
        [autoClose]="false"
      >
        <button
          id="button-basic"
          dropdownToggle
          type="button"
          class="btn"
          aria-controls="dropdown-basic"
        >
          <img src="assets/images/Vector_3.png" />
        </button>
        <ul
          id="dropdown-basic"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="button-basic"
        >
          <li role="menuitem">
            <a class="dropdown-item" href="#"
              ><img src="assets/images/Menu_down.svg" />
              <span>My account</span></a
            >
          </li>
          <li role="menuitem">
            <a class="dropdown-item" href="#">
              <li class="cursor-pointer" (click)="logout()">
                <img src="assets/images/logout_down.svg" /> <span>Log out</span>
              </li></a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="search_tab">
      <input type="text" placeholder="Search" />
      <span><img src="assets/images/search_tab1.png" /></span>
    </div>
    <div class="need_help cursor-pointer">
      <span><img src="assets/images/bell.png" /></span>
    </div>

    <div
      class="btn-group right_dp"
      dropdown
      #dropdown="bs-dropdown"
      [autoClose]="false"
    >
      <button
        id="button-basic"
        dropdownToggle
        type="button"
        class="btn"
        aria-controls="dropdown-basic"
      >
<!--        <img src="assets/images/user_photo.png" />-->
        <div class="artist_name_type">
          <div class="artist_img">
            <img *ngIf="currentUser?.photo" [src]="currentUser?.photo" />
          </div>
        </div>

      </button>
      <ul
        id="dropdown-basic"
        *dropdownMenu
        class="dropdown-menu"
        role="menu"
        aria-labelledby="button-basic"
      >
        <li role="menuitem">
          <a class="dropdown-item" href="#"
            ><img src="assets/images/Menu_down.svg" />
            <span>My account</span></a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" href="#">
            <li class="cursor-pointer" (click)="logout()">
              <img src="assets/images/logout_down.svg" /> <span>Log out</span>
            </li></a
          >
        </li>
      </ul>
    </div>
  </header>
  <header class="top_bar_mob">
    <div class="logo_with_header">
      <div class="mob_header_name">
        <div class="mob_user_name">
          <img src="assets/images/kwot_logo.png" />
        </div>
        <div
          class="btn-group left_dp"
          dropdown
          #dropdown="bs-dropdown"
          [autoClose]="false"
        >
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn"
            aria-controls="dropdown-basic"
          >
            <img src="assets/images/Vector_3.png" />
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic"
          >
            <li role="menuitem">
              <a class="dropdown-item" href="#"
                ><img src="assets/images/Menu_down.svg" />
                <span>My account</span></a
              >
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">
                <li class="cursor-pointer" (click)="logout()">
                  <img src="assets/images/logout_down.svg" />
                  <span>Log out</span>
                </li></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="mob_notofication">
        <img src="assets/images/mob_Notification.png" />
      </div>
    </div>

    <div class="hi_user_name">
      <h1>Hi, {{ currentUser.first_name }} {{ currentUser.last_name }}</h1>
    </div>
  </header>
</div>
