import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {StudioStatus, User} from "@frontend/data-models";
import {select, Store} from "@ngrx/store";
import {
  AdminState,
  getAllListener,
  GetAllListener,
  getSuccess,
  ResetAdminState, UpdateListener
} from "@frontend/admin-store";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CustomModalComponent} from "@frontend/shared";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'frontend-listeners',
  templateUrl: './listeners.component.html',
  styleUrls: ['./listeners.component.scss'],
})
export class ListenersComponent implements OnDestroy {
  unsubscriber = new Subject();
  listeners: User[] = [];
  timeout: any = null;
  searchData = '';
  queryParams: any = {};
  sort: any = {};
  isUpdateAction = false;
  dataCount = 500000;
  studioStatus = StudioStatus;
  currentUser: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private adminStore: Store<AdminState>,
    private modelService: BsModalService,
    private toastr: ToastrService,
  ) {
    this.adminStore.dispatch(ResetAdminState({params: {listeners: null, success: ''}}));
    this.activeRoute.queryParams.subscribe(query => {
      this.queryParams = {
        page: parseInt(query.page || 1),
        limit: 10,
        search: query.search || '',
        sort: query.sort || '',
        filter: query.filter || 'all'
      };
      this.searchData = query.search || ''
      this.sort = query.sort ? JSON.parse(query.sort) : {}
      this.callApi();
    });
    this.subscribeToStore();
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

  callApi() {
    this.adminStore.dispatch(GetAllListener({
      params: {
        ...this.queryParams,
        allFields: true
      }
    }));
  }

  redirectBack() {
    history.back();
  }

  subscribeToStore() {
    this.adminStore.pipe(select(getAllListener))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(listeners => {
        if (listeners) {
          this.listeners = [...listeners.data];
          this.dataCount = listeners.count.total
        }
      })
    this.adminStore.pipe(select(getSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.isUpdateAction) {
            this.isUpdateAction = false;
            this.toastr.success(success)
            this.callApi();
          }
        }
      })
  }

  search(value: string) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      value = value.trim();
      this.router.navigate(['/listeners'], {
          queryParams: {
            ...this.queryParams,
            search: value
          },
          queryParamsHandling: 'merge'
        }
      );
    }, 1500);
  }

  sortField(field: string) {
    if (this.sort.field === field) {
      this.sort = {field, dir: this.sort.dir === 'dec' ? 'inc' : 'dec'};
    } else {
      this.sort = {field, dir: 'dec'};
    }
    this.router.navigate(['/listeners'], {
        queryParams: {
          ...this.queryParams,
          sort: JSON.stringify(this.sort)
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  changePage(event: any) {
    this.router.navigate(['/listeners'], {
      queryParamsHandling: 'merge',
      queryParams: {
        ...this.queryParams,
        page: event.page
      }
    })
  }

  checkData() {
    if (this.currentUser.admin_role !== 'MANAGEMENT' && this.currentUser.admin_role !== 'IT') {
      return this.currentUser.permissions.some((item: any) => item.menu === 'listeners' && item.permissionType.some((per: any) => per === 'write'));
    } else {
      return this.currentUser.admin_role === 'MANAGEMENT' || this.currentUser.admin_role === 'IT';
    }
  }

  getCurrentUser(event: any) {
    this.currentUser = event;
  }

  activateMember(event: any, user: any) {
    event.preventDefault();
    event.stopPropagation();
    let body = {
      user_id: user._id,
      listener_status: !user.listener_status
    }
    this.adminStore.dispatch(UpdateListener({body}));
    this.isUpdateAction = true;
  }

  deactivateMember(event: any, user: any) {
    event.preventDefault();
    event.stopPropagation();
    const modelRef: BsModalRef = this.modelService.show(CustomModalComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model',
      initialState: {
        showImage: false
      }
    });
    modelRef.content.headerTitle = 'Are you sure to deactivate this listener?';
    modelRef.content.title = 'Deactivate Listener';
    modelRef.content.headerSubTitle = '';
    modelRef.content.confirmText = 'Deactivate Listener';
    modelRef.content.customClass = 'big-form-details';
    modelRef.content.closeEvent.subscribe((data: any) => {
      if (data) {
        let body = {
          user_id: user._id,
          listener_status: !user.listener_status
        }
        this.adminStore.dispatch(UpdateListener({body}));
        this.isUpdateAction = true;
      }
    })
  }
  blockUser(event: any, user: any) {
    event.preventDefault();
    event.stopPropagation();
    let body = {
      user_id: user._id,
      is_blocked_by_admin: !user.is_blocked_by_admin
    }
    this.adminStore.dispatch(UpdateListener({body}));
    this.isUpdateAction = true;
  }

  unBlockUser(event: any, user: any) {
    event.preventDefault();
    event.stopPropagation();
    const modelRef: BsModalRef = this.modelService.show(CustomModalComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model',
      initialState: {
        showImage: false
      }
    });
    modelRef.content.headerTitle = 'Are you sure to block this listener?';
    modelRef.content.title = 'Block Listener';
    modelRef.content.headerSubTitle = '';
    modelRef.content.confirmText = 'Block Listener';
    modelRef.content.customClass = 'big-form-details';
    modelRef.content.closeEvent.subscribe((data: any) => {
      if (data) {
        let body = {
          user_id: user._id,
          is_blocked_by_admin: !user.is_blocked_by_admin
        }
        this.adminStore.dispatch(UpdateListener({body}));
        this.isUpdateAction = true;
      }
    })
  }

}
