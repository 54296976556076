import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";
import * as fromArtist from "./+state/admin.reducer";
import {EffectsModule} from "@ngrx/effects";
import {AdminEffects} from "./+state/admin.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('admin', fromArtist.reducer),
    EffectsModule.forFeature([AdminEffects])
  ],
})
export class AdminStoreModule {
}
